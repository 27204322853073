<template>
	<button class="toggle_btn" :class="this.$store.state.header.isOpen ? 'on' : ''">
		<div class="top"></div>
		<div class="mid"></div>
		<div class="bottom"></div>
	</button>
</template>

<script setup></script>

<style scoped>
	.toggle_btn {
		position: fixed;
		top: calc(var(--header-height) / 2 - 10px);
		left: calc(95vw - 25px);
		display: none;
		width: 25px;
		height: 20px;
		padding: 0;
		border: none;
	}
	.toggle_btn > div {
		position: absolute;
		left: 0;
		width: 100%;
		height: 2px;
		background-color: var(--font-color-default);
		transition: var(--transition-slow);
	}
	.toggle_btn .top {
		top: 0;
	}
	.toggle_btn .mid {
		top: calc(50% - 1px);
	}
	.toggle_btn .bottom {
		bottom: 0;
	}
	.toggle_btn.on .top {
		top: calc(50% - 1px);
		transform: rotate(135deg);
	}
	.toggle_btn.on .mid {
		opacity: 0;
	}
	.toggle_btn.on .bottom {
		top: calc(50% - 1px);
		bottom: auto;
		transform: rotate(225deg);
	}
	/* 좁을때(1000px 이하)만 */
	@media screen and (max-width: 768px) {
		.toggle_btn {
			display: block;
		}
	}
</style>
