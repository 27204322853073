// <img :src=""  v-noImg/>
export const noImg = (el) => {
	const tagName = el.tagName;

	const setNoImage = () => {
		el.setAttribute('src', require('@/assets/img/common/no_img.svg'));
	};

	if (tagName === 'IMG') {
		if (!el.src) {
			setNoImage();
		}
		el.addEventListener('error', setNoImage);
	}
};
