<template>
    <div id="toTop" class="toTop" @click="toTop">TOP</div>
</template>

<script setup>
// 내장 라이브러리
import { onMounted, onUnmounted } from 'vue';

onMounted(() => {
    window.addEventListener('scroll', toTopScrolled);
});
onUnmounted(() => {
    window.removeEventListener('scroll', toTopScrolled);
});

const toTopScrolled = () => {
    const toTopEl = document.getElementById('toTop');
    if(window.scrollY > 50){
        toTopEl.classList.add('scrolled');
    } else{
        toTopEl.classList.remove('scrolled');
    }
}

const toTop = () => {
    window.scrollTo({top : 0, behavior : 'smooth'});
}

</script>

<style scoped>
/* toTop 시작 */
.toTop{
    position: fixed;
    right: 3rem;
    bottom: 3rem;
    width: 4.5rem;
    height: 4.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: var(--font-w-bold);
    letter-spacing: 0;
    color: var(--primary-color-contrast);
    background-color: var(--primary-color);
    /* box-shadow: var(--box-shadow); */
    opacity: 0;
    transition: .5s;
    /*mix-blend-mode: difference;*/     /* 색 반전효과. body에 배경색 #fff를 넣지 않으면 안보일 수도 있음 */
    z-index: 100;
    visibility: hidden;
    cursor: pointer;
}
/* 스크롤시 START */
.toTop.scrolled{
    opacity: 1;
    visibility: visible;
}
</style>