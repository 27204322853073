// import store from '@/store';
import axios from 'axios';
import store from '@/store';
import router from '@/router';

export default function useAxios() {
	function postData(url, payload, callback = () => {}, failCallback = () => {}) {
		store.commit('loading/startLoading'); // (화면 전체) 로딩 시작
		axios
			.post(url, payload, {
				headers: {},
			})
			.then((res) => {
				if (res.data.success) {
					if (callback) {
						callback(res.data);
					}
				} else {
					failCallback(res.data);
				}
				store.commit('loading/finishLoading'); // (화면 전체) 로딩 완료
			})
			// 서버와 통신자체가 실패한 상태
			.catch((error) => {
				console.error(error);
				store.commit('loading/finishLoading'); // (화면 전체) 로딩 완료
			});
	}

	function fetchData(url, params = {}, callback = () => {}, failCallback = () => {}) {
		store.commit('loading/startLoading'); // (화면 전체) 로딩 시작
		axios
			.get(url, {
				headers: {},
				params: params,
			})
			.then((res) => {
				if (res.data.success) {
					callback(res.data);
					store.commit('loading/finishLoading'); // (화면 전체) 로딩 완료
				} else {
					failCallback(res.data);
					store.commit('loading/finishLoading'); // (화면 전체) 로딩 완료
				}
			})
			.catch((error) => {
				// 서버랑 통신 자체가 안 된 경우
				const status = error?.message.slice(-3);
				if (status === '500' || status === '404') {
					router.push('/404');
					store.commit('loading/finishLoading'); // (화면 전체) 로딩 완료
				} else {
					window.alert('오류가 발생했습니다. 페이지를 새로고침 해주세요.');
					store.commit('loading/finishLoading'); // (화면 전체) 로딩 완료
				}
			});
	}

	return {
		postData,
		fetchData,
	};
}
