<template>
	<header class="gnb" :class="store.state.header.isOpen ? 'on' : ''">
		<div class="c_inner">
			<div class="gnb_logo">
				<router-link to="/">
					<img class="ci" src="@/assets/img/common/logo.png" alt="logo_ci" />
				</router-link>
			</div>
			<HeaderToggleBtn @click="toggleGnb('toggle')" />
			<nav class="gnb_menu">
				<li>
					<div>
						<span @click="() => router.push('/home')">홈</span>
					</div>
				</li>
				<li>
					<div>
						<span @click="() => router.push('/greetings')">회사소개</span>
					</div>
					<ul class="menu_sub">
						<li><router-link to="/greetings">인사말</router-link></li>
						<li><router-link to="/history">연혁</router-link></li>
						<li><router-link to="/organization">조직도</router-link></li>
						<li><router-link to="/certification_01">인증현황</router-link></li>
						<li><router-link to="/location">찾아오시는길</router-link></li>
					</ul>
				</li>
				<li>
					<div>
						<span @click="() => router.push({ name: 'ProductList', params: { code: '001' } })">제품소개</span>
					</div>
					<ul class="menu_sub">
						<li v-for="(item, idx) in productCategoryList" :key="idx">
							<router-link :to="{ name: 'ProductList', params: { code: item.code, name: item.name } }">{{
								item.name
							}}</router-link>
						</li>
						<!-- <li><router-link to="/productList_01">디자인형 울타리</router-link></li>
						<li><router-link to="/productList_02">알루미늄제 교량난간</router-link></li>
						<li><router-link to="/productList_03">방파제난간</router-link></li>
						<li><router-link to="/productList_04">자전거보관대</router-link></li>
						<li><router-link to="/productList_05">YL형울타리</router-link></li>
						<li><router-link to="/productList_06">차양</router-link></li>
						<li><router-link to="/productList_07">차량방호책</router-link></li>
						<li><router-link to="/productList_08">대문</router-link></li>
						<li><router-link to="/productList_09">이동식부스</router-link></li>
						<li><router-link to="/productList_10">기타</router-link></li> -->
					</ul>
				</li>
				<li>
					<div>
						<span @click="() => router.push('/referenceList')">자료실</span>
					</div>
					<ul class="menu_sub">
						<li><router-link to="/referenceList">시공사례</router-link></li>
					</ul>
				</li>
				<li>
					<div>
						<span @click="() => router.push('/noticeList')">고객지원</span>
					</div>
					<ul class="menu_sub">
						<li><router-link to="/noticeList">공지사항</router-link></li>
						<li><router-link to="/application">카달로그 신청</router-link></li>
					</ul>
				</li>
				<li class="nara">
					<div>
						<a href="https://www.g2b.go.kr:8092/" target="_blank">
							<img src="@/assets/img/common/nara.png" alt="나라장터종합쇼핑몰" />
						</a>
					</div>
				</li>
			</nav>
		</div>
	</header>
</template>

<script setup>
	import { onMounted, onUnmounted, ref } from 'vue';
	import HeaderToggleBtn from './HeaderToggleBtn.vue';
	import { useStore } from 'vuex';
	import router from '@/router';
	import { getMainCategory } from '@/api/product';

	const store = useStore();

	const productCategoryList = ref([]);

	const headerResizer = () => {
		if (window.innerWidth > 768) {
			toggleGnb('remove');
		}
	};
	const toggleGnb = (action = 'toggle') => {
		if (action == 'toggle') {
			store.commit('header/toggle');
		} else {
			store.commit('header/close');
		}
	};

	onMounted(() => {
		window.addEventListener('resize', headerResizer);
		// 제품소개 리스트 코드 조회
		getMainCategory()
			.then((res) => {
				productCategoryList.value = res.data;
				console.log(productCategoryList.value);
			})
			.catch((err) => window.alert(err));
	});
	onUnmounted(() => {
		window.removeEventListener('resize', headerResizer);
	});
</script>

<style scoped>
	.gnb {
		z-index: 1000;
		position: fixed;
		justify-content: space-between;
		overflow: hidden;
		top: 0;
		width: 100%;
		height: var(--header-height);
		background-color: #fff;
		border-bottom: 1px solid var(--border-color-light);
		transition: var(--transition-slow);
	}
	.gnb .c_inner {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		gap: 10rem;
		height: 100%;
	}
	.gnb .gnb_logo {
		flex-shrink: 0;
		height: var(--header-height);
	}
	.gnb .gnb_logo > a {
		display: flex;
		align-items: center;
		height: 100%;
		padding: 1rem 0;
	}
	.gnb .gnb_logo > a > svg {
		/* sample logo를 위한 선언. 나중에 지울 것 */
		width: 9rem;
		height: 100%;
	}
	.gnb .gnb_logo > a > img {
		height: 90%;
	}
	.gnb .gnb_menu {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		gap: 4rem;
		width: 100%;
		/* max-width: 60rem; */
		height: 100%;
		text-align: center;
	}
	.gnb .gnb_menu > li {
		width: 100%;
		white-space: nowrap;
	}
	.gnb .gnb_menu > li > div {
		display: flex;
		align-items: center;
		justify-content: center;
		height: var(--header-height);
		align-items: center;
		font-size: var(--font-size-large);
		font-weight: 600;
	}
	.gnb .gnb_menu > li > div > a,
	.gnb .gnb_menu > li > div > span {
		position: relative;
		display: inline-block;
		overflow: hidden;
		padding: 0.5rem 0;
		cursor: pointer;
	}
	.gnb .gnb_menu > li .menu_sub {
		flex-grow: 1;
		opacity: 0;
		transition: var(--transition-slow);
		font-weight: var(--font-w-regular);
	}
	.gnb .gnb_menu > li .menu_sub > li {
		line-height: 2;
	}
	.gnb .gnb_menu > li .menu_sub > li a {
		padding: 0.5rem 0;
		transition: var(--transition-fast);
	}
	.gnb .gnb_menu > li .menu_sub > li a:hover {
		color: var(--primary-color);
	}
	.gnb .gnb_menu > li .menu_sub > li a.router-link-exact-active {
		color: var(--primary-color);
		font-weight: var(--font-w-bold);
	}

	/* 넓을때(768px 이상)만 */
	@media screen and (min-width: 768px) {
		.gnb:hover {
			height: calc(var(--header-height) + 30rem);
		}
		.gnb:hover .gnb_menu > li .menu_sub {
			opacity: 1;
		}
		.gnb .gnb_menu > li > div > a::after,
		.gnb .gnb_menu > li > div > span::after {
			content: '';
			position: absolute;
			bottom: 0;
			left: calc(-100% - 1px);
			width: 100%;
			border-bottom: 2px solid var(--primary-color);
			transition: var(--transition-slow);
		}
		.gnb .gnb_menu > li > div.on > a::after,
		.gnb .gnb_menu > li > div.on > span::after {
			left: 0%;
		}
		.gnb .gnb_menu > li:hover > div > a::after,
		.gnb .gnb_menu > li:hover > div > span::after {
			/* left: -40%; */
			left: 0%;
		}
	}
	/* 좁을때(768px 이하)만 */
	@media screen and (max-width: 768px) {
		.gnb .c_inner {
			flex-direction: column;
			gap: 0;
		}
		.gnb .gnb_logo > a {
			padding: 1rem 0;
		}
		.gnb .gnb_menu {
			overflow-y: auto;
			flex-direction: column;
			justify-content: flex-start;
			max-width: 100%;
			height: 100%;
			padding-bottom: var(--space-large); /* mobile menu에서 맨 밑 가려짐 방지 */
			opacity: 0;
			transition: opacity var(--transition-slow);
			-ms-overflow-style: none; /* IE and Edge */
			scrollbar-width: none; /* Firefox */
		}
		/* Chrome, Safari, Opera*/
		.gnb .gnb_menu::-webkit-scrollbar {
			display: none;
		}
		.gnb .gnb_menu > li {
			/* border-bottom: 1px solid #aaa; */
			width: 100%;
			margin-bottom: 1rem;
		}
		.gnb .gnb_menu > li div {
			height: auto;
			padding: 0.5rem 1rem;
			background-color: #f0f0f0;
			font-weight: var(--font-w-bold);
		}
		.gnb .gnb_menu > li div > * {
			display: block;
			width: 100%;
		}
		.gnb .gnb_menu > li .menu_sub {
			padding: 0.5rem 1rem;
			opacity: 1;
		}
		.gnb.on {
			height: calc(100vh);
		}
		.gnb.on .gnb_menu {
			opacity: 1;
		}
	}

	.gnb .gnb_menu > li > div > a::after {
		display: none;
	}
	.gnb .gnb_menu > li.nara a img {
		width: 19rem;
		vertical-align: middle;
	}
</style>
