export default {
  namespaced: true,
  state: {
    isOpen: false, // 헤더 펼침 여부
  },
  mutations: {
    open(state) { // 열기
      state.isOpen = true;
      document.body?.classList.add('c_no_overflow'); // body태그 scrollbar 보이는것 방지
    },
    close(state) {  // 접기
      state.isOpen = false;
      document.body?.classList.remove('c_no_overflow');
    },
    toggle(state){  // 토글
      state.isOpen = !state.isOpen;
      document.body?.classList.toggle('c_no_overflow');
    }
  }
}