import useAxios from '@/utils/api/useAxios';

const { fetchData, postData } = useAxios();

export function getReference(params) {
	return new Promise((resolve, reject) => {
		fetchData(
			`/getReference`,
			params,
			(res) => {
				resolve(res);
			},
			(fail) => {
				reject(fail.error);
			},
		);
	});
}

export function getMainReference(params) {
	return new Promise((resolve, reject) => {
		fetchData(
			`/getMainReference`,
			params,
			(res) => {
				resolve(res);
			},
			(fail) => {
				reject(fail.error);
			},
		);
	});
}

export function viewReference(params) {
	return new Promise((resolve, reject) => {
		fetchData(
			`/viewReference`,
			params,
			(res) => {
				resolve(res);
			},
			(fail) => {
				reject(fail.error);
			},
		);
	});
}
// postData()
