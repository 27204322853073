<template>
  <div class="c_screen_filter" v-if="alertContent || confirmContent || errorContent"></div>
  <transition name="bundle-popup">
    <div class="alert_bundle alert" v-if="alertContent">
      <div class="content">
        <p>{{alertContent}}</p>
        <button class="btn c_button-primary" id="alert_close_btn" @click="closeAlert">확인</button>
      </div>
    </div>
  </transition>

  <transition name="bundle-popup">
    <div class="alert_bundle confirm" v-if="confirmContent">
      <div class="content">
        <p>{{confirmContent}}</p>
        <button class="btn c_button-primary-reverse" @click="confirmContinue">확인</button>
        <button class="btn c_button-primary" id="confirm_close_btn" @click="confirmCancel">취소</button>
      </div>
    </div>
  </transition>

  <transition name="bundle-popup">
    <div class="alert_bundle error" v-if="errorContent">
      <div class="content">
        <p>{{errorContent}}</p>
        <button class="btn c_button-danger" id="error_close_btn" @click="closeError">확인</button>
      </div>
    </div>
  </transition>


  <transition-group name="fade">
    <div class="check"
      v-for="item in checkContent"
      :key="item" 
    >
      <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#fff"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/></svg>
      {{item}}
    </div>
  </transition-group>
    
</template>

<script setup>
import { computed, onUpdated } from 'vue';
import { useStore } from 'vuex'

const store = useStore();

const alertContent = computed(() => store.alertContent);
const confirmContent = computed(() => store.confirmContent);
const errorContent = computed(() => store.errorContent);
const checkContent = computed(() => store.checkContent);

onUpdated(() => {
  if(alertContent.value){
      document.getElementById('alert_close_btn').focus();
    } else if(confirmContent.value){
      document.getElementById('confirm_close_btn').focus();
    } else if(errorContent.value) {
      document.getElementById('error_close_btn').focus();
    }
});

const closeAlert = () => {
  store.commit('alertBundle/closeAlert');
}

const confirmContinue = () => {
  store.commit('alertBundle/confirmContinue');
}

const confirmCancel = () => {
  store.commit('alertBundle/confirmCancel');
}

const closeError = () => {
  store.commit('alertBundle/closeError');
}
</script>

<style scoped>
/* alert_bundle 공통 시작 */
.c_screen_filter{
  z-index: 1000;
}
.alert_bundle{
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 40rem;
  min-width: calc(var(--min-width) * 0.9);
  width: 90%;
  background-color: #fff;
  border-radius: .8rem;
  z-index: 1000;
}
.alert_bundle .content{
  height: calc(100% - 10rem);
  border-radius: 1rem;
  padding: 2rem;
  text-align: center;
  font-size: 2rem;
  word-break: keep-all;
}
.alert_bundle .content p{
  font-weight: var(--font-w-mid);
}
.alert_bundle .content .btn{
  display: inline-block;
  font-size: 1.6rem;
  padding: .9rem 2.6rem;
  border-radius: var(--border-radius-small);  /* 원래 .8rem */
  margin-top: 2rem;
  cursor: pointer;
}
.alert_bundle .content .btn:nth-of-type(2){
  margin-left: 1rem;
}
/* alert_bundle 공통 끝 */

/* alert */

/* confirm */
.confirm .content .btn_continue{
  background-color: var(--base-color-light);
  margin-right: 10px;
  color: #000;
}
.confirm .content .btn_continue:hover{
  filter: brightness(0.93);
}

/* error */
.error{
  border: 1px solid var(--danger-color);
}

@media screen and (max-width: 40rem) {
  .alert_bundle .content {
    font-size: 1.8rem;
  }
}

/* check */
.check{
  position: fixed;
  bottom: 2rem;
  left: 50%;
  transform: translate(-50%, 0%);
  padding: 0.8rem 1.5rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  background-color: rgba(40, 167, 69, 0.9);
  color: #fff;
  border-radius: .8rem;
  z-index: 1000;
}
.check svg{
  margin-top: -0.1rem;
}

/* transition bundle-popup (common으로 옮기면 동작X) */
.bundle-popup-enter-from,
.bundle-popup-leave-to {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.9);
}
.bundle-popup-enter-active,
.bundle-popup-leave-active {
    transition: all .1s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
    transition: all .2s ease-in-out;
}
</style>