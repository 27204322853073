import { createStore } from "vuex";
// import createPersistedState from "vuex-persistedstate";

import alertBundle from "./modules/alertBundle";
import loading from "./modules/loading";
import header from "./modules/header";

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    alertBundle,
    loading,
    header,
  },
  plugins: [
    // createPersistedState({
    //     // storage: window.sessionStorage,
    //     paths: ["access_token"],
    // }),
  ],
});