import useAxios from '@/utils/api/useAxios';

const { fetchData, postData } = useAxios();

// 제품상세 - 상위 카테고리 리스트 조회
export function getMainCategory(params) {
	return new Promise((resolve, reject) => {
		fetchData(
			`/getMainCategory`,
			params,
			(res) => {
				resolve(res);
			},
			(fail) => {
				reject(fail.error);
			},
		);
	});
}

// 제품상세 - 하위 카테고리 리스트 조회
export function getSubCategory(params) {
	return new Promise((resolve, reject) => {
		fetchData(
			`/getSubCategory`,
			params,
			(res) => {
				resolve(res);
			},
			(fail) => {
				reject(fail.error);
			},
		);
	});
}

// 제품상세 - 하위 카테고리에 해당하는 제품 리스트 조회
export function getProduct(params) {
	return new Promise((resolve, reject) => {
		fetchData(
			`/getProduct`,
			params,
			(res) => {
				resolve(res);
			},
			(fail) => {
				reject(fail.error);
			},
		);
	});
}

// 제품상세 - 상세 조회
export function viewProduct(params) {
	return new Promise((resolve, reject) => {
		fetchData(
			`/viewProduct`,
			params,
			(res) => {
				resolve(res);
			},
			(fail) => {
				reject(fail.error);
			},
		);
	});
}
