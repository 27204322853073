<template>
  <transition name="fade">
    <div v-show="loading" class="c_screen_filter">
      <!-- <div class="loading-default"></div> -->
      <!-- <lottie-player class="loading-custom" src="https://assets5.lottiefiles.com/packages/lf20_brzkghfk.json" background="transparent" speed="0.9"  style="width: 300px; height: 300px;" loop autoplay></lottie-player> -->
      <svg class="loading-spinner" viewBox="0 0 50 50" v-if="loading">
        <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
      </svg>
    </div>
  </transition>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex'

const store = useStore();
const loading = computed(() => store.loading);
</script>

<style scoped>
.c_screen_filter{
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 100;
}
[class|="loading"]{
  z-index: 100;
  position: absolute;
  top: 50%;
  left: 50%;
}
.loading-default {
  width: 5rem;
  height: 5rem;
  margin: -5rem 0 0 -2.5rem;
  border-radius: 50%;
  border: 0.6rem solid rgba(230,230,230, 0.7);
  border-top-color: var(--primary-color);
  animation: c_spining 0.7s ease-in-out infinite;
}
.loading-custom{
  transform: translate(-50%, -50%);
}
.loading-spinner {
  width: 5rem;
  height: 5rem;
  margin: -5rem 0 0 -2.5rem;
  background: none;
  animation: c_spining 1.85s linear infinite;
}
.loading-spinner .path {
  stroke: var(--primary-color);
  stroke-linecap: round;
  animation: dash 1.65s ease-in-out infinite;
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
    transition: all .2s ease-in-out;
}
</style>