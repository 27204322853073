import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';
import { contactDash, dateFormat, priceFormat } from '@/utils/formatting/format';

import slide from './directive/slide';
import fade from './directive/fade';
import { noImg } from './directive/noImg';

// 기본 url
// 아래의 방법은 .env 파일이 root 폴더에 있어야 한다.
axios.defaults.baseURL = 'https://admin.hosanfence.co.kr/api';
const app = createApp(App);
app.directive('slide', slide);
app.directive('fade', fade);
app.directive('noImg', noImg);
app.directive('numberonly', (el) => {
	// <input v-numberonly>
	// 숫자만 입력받고, 문자는 입력받지 않음
	el.addEventListener('input', (e) => {
		e.target.value = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
	});
});
app.config.globalProperties.axios = axios;
app.config.globalProperties.$priceFormat = priceFormat;
app.config.globalProperties.$dateFormat = dateFormat;
app.config.globalProperties.$contactFormat = contactDash;
app.use(store).use(router).mount('#app');
