<template>
	<div class="content Home">
		<section class="c_section">
			<div class="top_banner home">
				<h1>최고의 <span>품질</span>과 <span>성실</span> 시공</h1>
				<h2>주식회사 호산이 이뤄 나가겠습니다</h2>
			</div>
		</section>
		<section class="c_section about_company">
			<div class="c_inner">
				<div class="title_box">
					<p class="section_title">ABOUT COMPANY</p>
					<p class="title">주식회사 호산을 방문해주셔서 감사합니다.</p>
					<p class="text">
						저희 호산은 충실한 기본을 바탕으로 성실과 책임시공을 통해 고객 만족에 <br />한 걸음 다가갈 수 있도록 최선의
						노력을 다하고 있습니다.
					</p>
				</div>
			</div>
		</section>
		<section class="c_section product">
			<div class="c_inner">
				<div class="title_box">
					<p class="section_title">OUR PRODUCT</p>
					<p class="title">제품소개</p>
				</div>
				<div class="product_container">
					<ul class="grid-container">
						<li v-for="(item, idx) in mainCategoryList" :key="idx" class="item1">
							<router-link :to="{ name: 'ProductList', params: { code: item.code } }">
								<div class="img_box">
									<img :src="require(`@/assets/img/mainCategoryImg/img_product_${item.code}.jpg`)" alt="이미지" />
								</div>
								<p>{{ item.name }}</p>
							</router-link>
						</li>
					</ul>
				</div>
			</div>
		</section>
		<section class="c_section reference">
			<div class="title_box">
				<p class="section_title">REFERENCE</p>
				<p class="title">시공사례</p>
			</div>
			<div class="reference_container">
				<div class="slide">
					<swiper v-bind="SwiperSlideOption" class="mySwiper c_list gallery">
						<swiper-slide v-for="(item, idx) in mainReferenceList" :key="idx">
							<div class="img_box">
								<img :src="item.file_path" :alt="item.reference_name" />
							</div>
							<p>{{ item.reference_name }}</p>
						</swiper-slide>
					</swiper>
				</div>
			</div>
		</section>
		<section class="c_section center">
			<div class="c_inner">
				<div class="title_box">
					<p class="section_title">CONTACT US</p>
					<p class="title">고객센터</p>
				</div>
				<div class="center_container">
					<div class="left_content">
						<div class="img_wrap">
							<img src="@/assets/img/home/icon_headset.svg" alt="이미지" />
						</div>
						<div class="text_box">
							<p>궁금한 사항이 있으신가요?</p>
							<p>언제든 궁금한 점 연락주세요.<br />친절하게 도와드리겠습니다.</p>
						</div>
					</div>
					<ul class="company_info">
						<li>
							<img src="@/assets/img/home/icon_tel.svg" alt="전화번호" />
							<span>033-572-1562~3</span>
						</li>
						<li>
							<img src="@/assets/img/home/icon_fax.svg" alt="팩스" />
							<span>033-572-1540</span>
						</li>
						<li>
							<img src="@/assets/img/home/icon_mail.svg" alt="이메일" />
							<span>hosan1562@naver.com</span>
						</li>
						<li>
							<img src="@/assets/img/home/icon_map.svg" alt="주소" />
							<span>강원특별자치도 삼척시 근덕면 본동길 21-205</span>
						</li>
					</ul>
				</div>
			</div>
		</section>
	</div>
</template>

<script setup>
	// vue 내장 라이브러리
	import { Swiper, SwiperSlide } from 'swiper/vue';
	import { ref, defineEmits, defineProps, onMounted } from 'vue';
	// 스와이퍼
	import 'swiper/css';
	import 'swiper/css/bundle';
	import { FreeMode } from 'swiper';
	// api
	import { getMainReference } from '@/api/reference';
	import { getMainCategory } from '@/api/product';

	const mainReferenceList = ref([]);
	const mainCategoryList = ref([]);

	const fetchMainReference = () => {
		getMainReference().then((res) => {
			mainReferenceList.value = res.data;
		});
	};

	onMounted(() => {
		fetchMainReference();
		getMainCategory().then((res) => {
			mainCategoryList.value = res.data;
		});
	});

	const SwiperSlideOption = {
		slidesPerView: 4,
		spaceBetween: 20,
		centeredSlides: false,
		freeMode: false,
		scrollbar: false,
		breakpoints: {
			479: {
				slidesPerView: 1.8,
			},
			600: {
				slidesPerView: 2.2,
			},
			// 768px 이상일 때
			768: {
				slidesPerView: 3,
				spaceBetween: 16,
				freeMode: false,
				// pagination:true,
			},
			// 1200px 이상일 때
			1200: {
				slidesPerView: 4,
				spaceBetween: 24,
				freeMode: false,
			},
		},
		modules: [FreeMode],
	};
</script>

<style scoped>
	.top_banner.home {
		height: 60rem;
	}

	.top_banner.home h1 {
		font-size: 6rem;
		font-weight: var(--font-w-regular);
		line-height: 1.2;
	}

	.top_banner.home h1 span {
		font-weight: var(--font-w-bold);
	}

	.top_banner.home h2 {
		font-size: var(--font-size-x-large);
	}

	@media screen and (max-width: 768px) {
		.top_banner.home h1 {
			font-size: 5rem;
		}
	}

	@media screen and (max-width: 480px) {
		.top_banner.home h1 {
			font-size: 4rem;
		}
	}

	@media screen and (max-width: 380px) {
		.top_banner.home h1 {
			font-size: 3.6rem;
		}
	}

	.Home .c_section {
		padding: var(--space-large) 0;
	}

	.Home .c_section:first-child {
		padding: unset;
	}

	.Home .c_section + .c_section {
		margin-top: unset;
	}

	.c_section .title_box {
		margin: var(--space-mid) 0;
		text-align: center;
	}

	.c_section .title_box .section_title {
		font-size: var(--font-size-large);
		font-weight: var(--font-w-semibold);
		color: var(--font-color-sub);
	}

	.c_section .title_box .title {
		margin: 1rem 0;
		font-size: var(--font-size-xxx-large);
		font-weight: var(--font-w-bold);
		line-height: 1.2;
	}

	.about_company {
		display: flex;
		align-items: center;
		min-height: 68rem;
	}

	.about_company .title_box {
		display: flex;
		flex-direction: column;
		gap: 1.2rem 0;
		text-align: left;
	}

	.about_company .title_box .title {
		font-size: var(--font-size-xxx-large);
		font-weight: var(--font-w-bold);
		line-height: 1.2;
	}

	.about_company .title_box .text {
		text-align: left;
		font-size: var(--font-size-large);
	}

	.about_company {
		background: url('~@/assets/img/home/bg_company.png') center/cover no-repeat;
	}

	@media screen and (max-width: 768px) {
		.about_company .title_box {
			gap: 2rem 0;
		}

		.about_company .title_box .title {
			font-size: var(--font-size-xx-large);
		}

		.about_company .title_box .text br {
			display: none;
		}
	}

	.grid-container {
		/* display: grid;
	grid-template-columns: auto auto auto auto; */
		display: flex;
		flex-wrap: wrap;
		gap: var(--space-small);
	}

	.grid-container li {
		/* display: grid;
	grid-template-columns: auto auto auto auto; */
		flex-basis: calc((100% - (var(--space-small)) * 3) / 4);
	}

	.grid-container a {
		position: relative;
	}

	.grid-container a p {
		z-index: 1;
		position: absolute;
		top: 50%;
		left: 50%;
		color: #fff;
		transform: translate(-50%, -50%);
		width: 90%;
		text-align: center;
		font-size: var(--font-size-large);
		font-weight: var(--font-w-semibold);
		line-height: 1.2;
	}

	.grid-container a::before {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.5);
	}

	/* .grid-container .item1 {
	flex-basis: calc((100% - var(--space-small)) / 2);
}

.grid-container .item2 {
	flex-basis: calc((100% - var(--space-small)) / 2);
} */

	/* .grid-container li.item1 img,
.grid-container li.item2 img {
	height: 40rem;
} */

	.grid-container li img {
		height: 20rem;
	}

	@media screen and (max-width: 768px) {
		.grid-container {
			/* display: grid;
		grid-template-columns: auto auto;
		gap: var(--space-small); */
		}

		.grid-container li {
			flex-basis: calc((100% - (var(--space-small)) * 1) / 2);
		}

		/* .grid-container .item1 {
		flex-basis: 100%;
	}

	.grid-container .item2 {
		flex-basis: 100%;
	} */
	}
	@media screen and (max-width: 480px) {
		/* .grid-container li.item1 img,
	.grid-container li.item2 img {
		height: 30rem;
	} */

		.grid-container li img {
			height: 14rem;
		}
	}

	.reference {
		text-align: center;
	}

	.reference .c_list.gallery .img_box {
		margin-bottom: 1rem;
	}

	.reference .c_list.gallery .img_box img {
		height: 40rem;
	}

	.reference .c_list.gallery p {
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
	}

	@media screen and (max-width: 1600px) {
		.reference .c_list.gallery .img_box img {
			height: 35rem;
		}
	}

	@media screen and (max-width: 1200px) {
		.reference .c_list.gallery .img_box img {
			height: 25rem;
		}
	}

	@media screen and (max-width: 768px) {
		.reference .c_list.gallery .img_box img {
			height: 20rem;
		}
	}

	@media screen and (max-width: 480px) {
		.reference .c_list.gallery .img_box img {
			height: 16rem;
		}
	}

	.center {
		background-color: var(--base-color-light);
	}

	.center_container {
		display: flex;
		align-items: center;
		justify-content: space-evenly;
		gap: 6rem 0;
		background: #fff;
		padding: 8rem 0;
	}

	.center_container .left_content {
		display: flex;
		align-items: center;
		gap: 2rem;
	}

	.center_container .left_content .img_wrap {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 11rem;
		height: 11rem;
		border-radius: var(--border-radius-full);
		background-color: var(--base-color-light);
	}

	.center_container .left_content .img_wrap img {
		width: 5.6rem;
	}

	.center_container .left_content .text_box p:first-child {
		margin-bottom: 1rem;
		padding-bottom: 1rem;
		border-bottom: 1px solid var(--border-color-default);
		line-height: 1;
		font-size: var(--font-size-x-large);
		font-weight: var(--font-w-bold);
	}

	.center_container .left_content .text_box p:last-child {
		font-size: var(--font-size-large);
	}

	@media screen and (max-width: 900px) {
		.center_container {
			flex-direction: column;
		}

		.center_container .left_content .img_wrap {
			display: none;
		}
	}

	@media screen and (max-width: 480px) {
		.center_container .left_content {
			flex-direction: column;
		}

		.center_container {
			flex-direction: column;
		}
	}
</style>
