import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import store from '@/store';

const routes = [
	{
		path: '/',
		name: 'home',
		component: Home,
	},

	{
		// 홈
		path: '/home',
		name: 'Home',
		component: () => import(/* webpackChunkName: "Home" */ '../views/Home.vue'),
	},

	{
		// 회사소개-인사말
		path: '/greetings',
		name: 'Greetings',
		component: () => import(/* webpackChunkName: "Greetings" */ '../views/Company/Greetings.vue'),
	},

	{
		// 회사소개-연혁
		path: '/history',
		name: 'History',
		component: () => import(/* webpackChunkName: "History" */ '../views/Company/History.vue'),
	},

	{
		// 회사소개-조직도
		path: '/organization',
		name: 'Organization',
		component: () => import(/* webpackChunkName: "Organization" */ '../views/Company/Organization.vue'),
	},

	{
		// 회사소개-인증현황(all)
		path: '/certification_01',
		name: 'Certification_01',
		component: () => import(/* webpackChunkName: "Certification_01" */ '../views/Company/Certification_01.vue'),
	},

	{
		// 회사소개-인증현황(특허)
		path: '/certification_02',
		name: 'Certification_02',
		component: () => import(/* webpackChunkName: "Certification_02" */ '../views/Company/Certification_02.vue'),
	},

	{
		// 회사소개-인증현황(디자인등록)
		path: '/certification_03',
		name: 'Certification_03',
		component: () => import(/* webpackChunkName: "Certification_03" */ '../views/Company/Certification_03.vue'),
	},

	{
		// 회사소개-인증현황(기타인증서)
		path: '/certification_04',
		name: 'Certification_04',
		component: () => import(/* webpackChunkName: "Certification_04" */ '../views/Company/Certification_04.vue'),
	},

	{
		// 회사소개-찾아오시는길
		path: '/location',
		name: 'Location',
		component: () => import(/* webpackChunkName: "Location" */ '../views/Company/Location.vue'),
	},

	{
		// 제품소개-리스트
		path: '/productList/:code',
		name: 'ProductList',
		component: () => import(/* webpackChunkName: "ProductList" */ '../views/Product/ProductList.vue'),
	},

	{
		// 제품소개-상세
		path: '/productView/:product_no',
		name: 'ProductView',
		component: () => import(/* webpackChunkName: "ProductView" */ '../views/Product/ProductView.vue'),
	},

	{
		// 자료실-시공사례 리스트
		path: '/referenceList',
		name: 'ReferenceList',
		component: () => import(/* webpackChunkName: "ReferenceList" */ '../views/Reference/ReferenceList.vue'),
	},

	{
		// 자료실-시공사례 상세
		path: '/referenceView/:reference_no',
		name: 'ReferenceView',
		component: () => import(/* webpackChunkName: "ReferenceView" */ '../views/Reference/ReferenceView.vue'),
	},

	{
		// 고객지원-공지사항 리스트
		path: '/noticeList',
		name: 'NoticeList',
		component: () => import(/* webpackChunkName: "NoticeList" */ '../views/Support/NoticeList.vue'),
	},

	{
		// 고객지원-공지사항 상세
		path: '/noticeView/:notice_no',
		name: 'NoticeView',
		component: () => import(/* webpackChunkName: "NoticeView" */ '../views/Support/NoticeView.vue'),
	},

	{
		// 고객지원-카달로그 신청
		path: '/application',
		name: 'Application',
		component: () => import(/* webpackChunkName: "Application" */ '../views/Support/Application.vue'),
	},

	{
		// 404 페이지
		path: '/404',
		name: '404',
		component: () => import(/* webpackChunkName: "404" */ '../views/404.vue'),
	},
	{
		// 존재하지 않는 페이지 404로 리다이렉트
		path: '/:pathMatch(.*)*',
		redirect: '/404',
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	// 맨 위로
	scrollBehavior(to, from, savedPosition) {
		// 기존 위치 존재하면 그 위치 반환
		if (savedPosition) {
			return savedPosition;
		}
		// 쿼리만 다를 때는 스크롤 업 안함
		if (to.matched[0]?.path === from.matched[0]?.path && to.path !== '/') {
			return;
		}
		if (to.path === from.path || to.path === '/') {
			window.scrollTo({ top: 0, behavior: 'smooth' });
			return;
		}

		window.scrollTo({ top: 0 });
	},
});

// 페이지 변경시 로딩 시작
router.beforeEach(() => {
	store.commit('loading/startDelayLoading');
	store.commit('header/close'); // 다른 페이지로 넘어갈 때 헤더 닫기(뒤로가기 때문)
});
// 페이지 변경 완료시 로딩 끝냄
router.afterEach(() => {
	store.commit('loading/finishLoading');
});

export default router;
