<template>
	<footer class="global_footer">
		<div class="c_inner">
			<div class="footer_info">
				<ul class="info_detail">
					<li>
						<span>회사명: 주식회사 호산</span>
						<span>사업자번호: 222-81-23373</span>
						<span>주소: 강원특별자치도 삼척시 근덕면 본동길 21-205</span>
					</li>
					<li>
						<span>연락처: 033-572-1562~3</span>
						<span>팩스: 033-572-1540</span>
						<span>이메일: hosan1562@naver.com</span>
					</li>
				</ul>
				<p class="copyright">
					&copy; {{ new Date().getFullYear() }} Copyright ⓒ 주식회사 호산 Co., Ltd. All right reserved
				</p>
			</div>
		</div>
	</footer>
</template>

<script setup></script>

<style scoped>
	.global_footer {
		margin-top: 8rem;
		width: 100%;
		/* background: #222; */
		color: #fff;
		font-size: 1.5rem;
		font-weight: var(--font-w-regular);
		border-top: 1px solid var(--border-color-light);
	}
	.global_footer .c_inner {
		padding: var(--space-small) 0;
	}
	.global_footer .footer_menu {
		display: flex;
		flex-wrap: wrap;
		column-gap: 3rem;
		row-gap: 0.4rem;
		padding-bottom: var(--space-small);
		border-bottom: 1px solid var(--border-color-dark);
	}
	.global_footer .footer_info {
		color: var(--font-color-sub);
		text-align: center;
	}
	.global_footer .footer_info .info_detail {
		display: flex;
		justify-content: center;
		flex-direction: column;
		flex-wrap: wrap;
		column-gap: 3rem;
	}
	.global_footer .footer_info .info_detail span + span::before {
		content: '';
		display: inline-block;
		width: 0.1rem;
		height: 1.2rem;
		margin: 0 2rem;
		vertical-align: center;
		background: #ddd;
	}
	.global_footer .footer_info .copyright {
		margin-top: 2rem;
	}
	.global_footer .ci {
		margin-top: var(--space-small);
		text-align: right;
		max-width: 15rem;
	}

	@media screen and (max-width: 600px) {
		.global_footer .footer_menu {
			column-gap: 2rem;
		}
	}
</style>
